import styled from 'styled-components'

export default styled.div`
  width: 100%;
  &:last-child {
    .title-row-container {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .title-row-container {
    width: 100%;
    height: 45px;
    padding-left: 80px;
    padding-right: 30px;
    display: flex;
    background: #f7f7f7;
    .title-container {
      display: flex;
      align-items: center;
      width: 385px;
      font-family: 'Prompt';
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${(props) => props.theme.jadeGreen};
    }
    .options-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      .collpase-btn-container {
        width: 25px;
        height: 25px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .list-user-container {
    &.hide {
      display: none;
    }
  }
`
