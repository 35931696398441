/* eslint-disable no-useless-escape */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ArrowUpImg from '../../asset/images/user-collapse/arrow-up.svg'
import ArrowDownImg from '../../asset/images/user-collapse/arrow-down.svg'

import UserCollapseWrapperStyled from './styledComponent'

class UserCollapseWrapper extends PureComponent {
  state = {
    isExpand: false
  }

  getTitleContainer() {
    return (
      <div className="title-row-container">
        {this.getTitleSection()}
        {this.getOptionSection()}
      </div>
    )
  }

  getTitleSection() {
    return <div className="title-container">{this.props.title}</div>
  }

  getOptionSection() {
    return <div className="options-container">{this.getCollapseBtn()}</div>
  }

  handleCollapseBtnClicked = () => {
    this.setState({
      isExpand: !this.state.isExpand
    })
  }

  getCollapseBtn() {
    const arrowSrc = this.state.isExpand ? ArrowUpImg : ArrowDownImg
    return (
      <div className="collpase-btn-container" onClick={this.handleCollapseBtnClicked}>
        <img src={arrowSrc} alt="" />
      </div>
    )
  }

  getListUserContainer() {
    const className = this.state.isExpand ? 'list-user-container' : 'list-user-container hide'
    return <div className={className}>{this.props.children}</div>
  }

  render() {
    return (
      <UserCollapseWrapperStyled>
        {this.getTitleContainer()}
        {this.getListUserContainer()}
      </UserCollapseWrapperStyled>
    )
  }
}

UserCollapseWrapper.defaultProps = {
  title: ''
}

UserCollapseWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default UserCollapseWrapper
